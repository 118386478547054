import {
  FC,
  useEffect,
  useState,
} from 'react';
import cn from 'classnames';

import { useStableDateGetter } from 'src/shared/lib/date/hooks';
import { compareDates } from 'entities/PlainCalendar';

import styles from './AppointmentCalendar.module.scss';

import type { INavigationButton } from '../config/declarations';
import { MAX_DAYS_FOR_APPOINTMENTS } from '../config/constants';

const NavigationButton: FC<INavigationButton> = ({ dispatch, isLeft = false, date }) => {
  const [isVisible, setVisibility] = useState<boolean>(!isLeft);
  const specificClassName = styles[`arrow${isLeft
    ? 'Left'
    : 'Right'
  }`];
  const action = isLeft
    ? 'prev'
    : 'next';
  const getStableDate = useStableDateGetter();

  useEffect(() => {
    const stableNow = getStableDate(new Date());

    if (isLeft) {
      const isDatesEquals = compareDates(stableNow, date);

      if (isDatesEquals) {
        setVisibility(false);
      } else {
        setVisibility(() => true);
      }
    } else {
      stableNow.setDate(stableNow.getDate() + MAX_DAYS_FOR_APPOINTMENTS - 1)
      const isDatesEquals = compareDates(stableNow, date);

      if (isDatesEquals) {
        setVisibility(false);
      } else {
        setVisibility(() => true);
      }
    }

    if (isLeft && compareDates(stableNow, date)) {
      setVisibility(false);
    }
  }, [date, isLeft]);

  return (
    <button
      type="button"
      className={styles.navButton}
      disabled={!isVisible}
      onClick={() => dispatch({ action })}
    >
      <i className={cn(styles.arrow, specificClassName)} />
    </button>
  );
};

export default NavigationButton;
