import { FC } from 'react';
import cn from 'classnames';

import styles from 'components/Sections/ExponeaPsychicProfile/ExponeaPsychicProfile.module.scss';
import Man from 'src/shared/ui/Man/Man';

import type { QueueComponentInterface } from './config/declarations';

const Queue: FC<QueueComponentInterface> = ({ queue, peopleInQueue, isModal }) => {
  if (!queue) {
    return null;
  }

  const { title } = queue;
  const color = isModal ? 'white' : 'red';

  return (
    <div className={cn(styles.queue)}>
      <div className={styles.queueTitle}>
        <strong>{title}</strong>
        {' '}
        {peopleInQueue}
      </div>
      <div className={styles.queueIcons}>
        {new Array(peopleInQueue).fill('').map((_, i) => (
          <Man key={i.toString()} color={color} />
        ))}
        {new Array(6 - peopleInQueue).fill('').map((_, i) => (
          <Man key={i.toString()} color={color} type="outlined" />
        ))}
      </div>
    </div>
  );
};

export default Queue;
